/*
MAXPROMO
URL: ?
AUTHOR: Nabil Amer Thabit (nbilz//lab//dsign | @nbilz)
EMAIL: nbilz@live.com
CREATE DATE: Jul 30, 2023
UPDATE DATE: Jul 30, 2023
REVISION: 1
NAME: index.scss
TYPE: Cascading Style Sheet
DESCRIPTION: ScreenSize | Check your screen size!
PALETTE: -
*/

a,abbr,acronym,address,applet,article,aside,audio,b,big,blockquote,body,canvas,caption,center,cite,code,dd,del,details,dfn,dialog,div,dl,dt,em,embed,fieldset,figcaption,figure,font,footer,form,h1,h2,h3,h4,h5,h6,header,hgroup,hr,html,i,iframe,img,ins,kbd,label,legend,li,mark,menu,meter,nav,object,ol,output,p,pre,progress,q,rp,rt,ruby,s,samp,section,small,span,strike,strong,sub,summary,sup,table,tbody,td,tfoot,th,thead,time,tr,tt,u,ul,var,video,xmp{border:0;margin:0;padding:0;font-size:100%}html,body{height:100%}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}b,strong{font-weight:bold}img{color:transparent;font-size:0;vertical-align:middle;-ms-interpolation-mode:bicubic}ol,ul{list-style:none}li{display:list-item}table{border-collapse:collapse;border-spacing:0}th,td,caption{font-weight:normal;vertical-align:top;text-align:left}q{quotes:none}q:before,q:after{content:'';content:none}sub,sup,small{font-size:75%}sub,sup{line-height:0;position:relative;vertical-align:baseline}sub{bottom:-0.25em}sup{top:-0.5em}svg{overflow:hidden}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Regular.woff2') format('woff2'),
       url('./assets/font/Exo-Regular.woff') format('woff'),
       url('./assets/font/Exo-Regular.ttf') format('ttf'),
       url('./assets/font/Exo-Regular.eot') format('eot'),
       url('./assets/font/Exo-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Bold.woff2') format('woff2'),
       url('./assets/font/Exo-Bold.woff') format('woff'),
       url('./assets/font/Exo-Bold.ttf') format('ttf'),
       url('./assets/font/Exo-Bold.eot') format('eot'),
       url('./assets/font/Exo-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face{
  font-family: 'Exo';
  src: url('./assets/font/Exo-Light.woff2') format('woff2'),
       url('./assets/font/Exo-Light.woff') format('woff'),
       url('./assets/font/Exo-Light.ttf') format('ttf'),
       url('./assets/font/Exo-Light.eot') format('eot'),
       url('./assets/font/Exo-Light.svg') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

html{
  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body{
  background: #FFF;
  color: #999;
  font-size: 12px;
  line-height: 12px;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  margin: 0;
  font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
}

a{
  text-decoration: none;
  color: #666;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  &:hover{
    color: #333;
  }
}

// a[disabled]{
//   pointer-events: none;
//   opacity: .5;
// }

button,
input{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  font-family: 'Exo', Helvetica, Arial, sans-serif;
  box-sizing: border-box;
  font-size: 12px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -webkit-transition: all 200ms ease-in-out;

  // &:hover{}

  &:focus{
    outline: none;
  }
}

button{
  cursor: pointer;
  
  *{
    pointer-events: none;
  }
}

.Toastify__toast-container{
  bottom: 20px !important;
  // right: 21px !important;
  padding: 0 !important;

  .Toastify__toast{
    font-family: 'Exo', Helvetica, Arial, sans-serif !important;
    font-size: 12px;
    line-height: 16px;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    margin-top: 10px;
    margin-bottom: 0;
    padding: 15px 0 20px 0 !important;
    border-radius: 5px;
  
    .Toastify__close-button{
      width: 16px;
      margin: 0 10px 0 0;
    }

    .Toastify__progress-bar-theme--light{
      background: #999;
    }

    .Toastify__toast-body{
      padding: 0 10px 0 15px;

      > div{
        font-size: 14px;
        line-height: 18px;
        font-weight: bold;

        > span{
          display: block;
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
        }
      }
    }
  }
}

header{
  padding: 15px 20px;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, .95);
  z-index: 3;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  box-shadow: 0 20px 20px -10px rgba(0, 0, 0, .1);
  
  > a{
    display: block;
    font-size: 20px;
    line-height: 40px;
    margin: 0 auto;
    width: 185px;

    > svg{
      display: block;
      width: 100%;
      height: 40px;
    }
  }
}

.form{
  padding: 70px 0 96px 0;
  min-height: 100vh;
  box-sizing: border-box;

  > ul{
    width: 60%;
    padding: 20px;
    margin: -20px auto;
    box-sizing: border-box;

    > li{
      padding: 10px;
      border: 1px solid #EEE;
      margin: 20px 0;
      border-radius: 10px;

      &:last-child{
        border: none;
        padding: 0;
      }

      > .calc{
        position: relative;
        padding: 0 60px 0 0;

        > button{
          position: absolute;
          top: 5px;
          bottom: 5px;
          right: 5px;
          width: 50px;
          height: auto;
          font-size: 20px;
          background: #D10000;
          border-radius: 5px;
    
          > svg{
            margin: -2px 0 0 0;
            width: 16px;
            height: 16px;
          }
    
          &:hover{
            background: #A10000;
          }
        }
    
        > ul{
          overflow: hidden;
    
          > li{
            position: relative;
            padding: 5px;
            float: left;
            width: 100%;
            box-sizing: border-box;
        
            &:nth-child(1),
            &:nth-child(2){
              width: 50%;
            }
          }
        }
      }

      > .result{
        text-align: center;
        font-size: 72px;
        line-height: 72px;
        font-weight: 100;
        color: #666;
        padding-top: 5px;

        > .label{
          display: block;
          font-size: 11px;
          line-height: 15px;
          color: #999;
          font-weight: bold;
          margin: 0 0 2.5px 0;
        }
      }
    }

    .unit{
      position: absolute;
      top: 5px;
      right: 5px;
      height: 50px;
      width: 50px;
      text-align: center;
      line-height: 50px;
      background: #CCC;
      color: #FFF;
      border-radius: 0 5px 5px 0;
      font-size: 16px;
      font-weight: bold;
      transition: all 200ms ease-in-out;
      -moz-transition: all 200ms ease-in-out;
      -webkit-transition: all 200ms ease-in-out;
    }

    label{
      position: absolute;
      pointer-events: none;
      top: 1px;
      left: 16px;
      background: #FFF;
      padding: 0 5px;
      font-size: 11px;
      line-height: 11px;
    }

    input{
      border: 1px solid #CCC;
      height: 50px;
      border-radius: 5px;
      padding: 4px 62.5px 0 15px;
      background: #FFF;
      font-size: 18px;
      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &.no-unit{
        padding: 4px 15px 0 15px;
      }

      &:hover,
      &:focus{
        border: 1px solid #999;

        ~ .unit{
          background: #999;
        }
      }
    }

    button{
      background: #666;
      color: #FFF;
      border-radius: 10px;
      height: 50px;
      padding: 0 10px;
      font-weight: bold;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
      font-size: 14px;

      &:hover{
        background: #333;
      }

      &:disabled{
        opacity: .5;
      }

      > svg{
        display: inline-block;
        vertical-align: middle;
        margin: -2px 0 0 5px;
        width: 14px;
        height: 14px;
      }
    }
  }
}

footer{
  margin: -96px 0 0 0;
  text-align: center;
  padding: 20px 40px 40px 40px;
  font-size: 11px;
  line-height: 15px;
  background: rgba(255, 255, 255, .9);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);

  a{
    font-weight: bold;

    &:hover{
      margin: 0 5px;
    }

    > svg{
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      margin: -3px 0 0 0;
    }
  }

  > div{
    margin: 2.5px 0 0 0;
    font-size: 10px;
    line-height: 14px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
  .form{
    > ul{
      width: 75%;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){
  .form{
    > ul{
      width: 90%;
    }
  }
}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){
  .form{
    > ul{
      width: 90%;
    }
  }
  
  footer{
    padding: 20px 20px 40px 20px;
  }
}

@media screen and (max-width: 767px) and (orientation: portrait){
  .Toastify__toast-container{
    width: auto !important;
    left: 30px !important;
    right: 30px !important;

    .Toastify__toast{
      border-radius: 5px;
    }
  }

  .form{
    padding: 70px 0 67px 0;
    
    > ul{
      width: 100%;
  
      > li{
        > .calc{
          > ul{
            > li{
              &:nth-child(1),
              &:nth-child(2){
                width: 100%;
              }
            }
          }
        }
  
        > .result{
          font-size: 52px;
          line-height: 56px;
        }
      }
    }
  }

  footer{
    margin: -67px 0 0 0;
    padding: 0 20px 20px 20px;
  }
}

/*
@media screen and (min-width: 1025px) and (max-width: 1600px){}

@media screen and (min-width: 768px) and (max-width: 1024px){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){}

@media screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait){}

@media screen and (max-width: 767px){}

@media screen and (max-width: 767px) and (orientation: landscape){}

@media screen and (max-width: 767px) and (orientation: portrait){}
*/